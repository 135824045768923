//Component to manage user accounts.
//This includes the current account widget plus the sign in feature that appears on click.

import {FirebaseAuthentication} from "@capacitor-firebase/authentication"
import {
    createEmailPasswordLoginForm,
    createChangePasswordButton
} from "./emailPasswordLogin.js"
import adLibrary from "../../monetization/ad_library";


function createAccountManagerButton() {
    //First, create the button that will be displayed on the screen.
    let logInSpan = document.createElement("span")
    logInSpan.style.display = "none" //Don't display until we can sync with Firebase.
    logInSpan.classList.add("logInSpan")
    logInSpan.addEventListener("click", function() {
        adLibrary.setBannerStatus(adLibrary.BannerStatus.HIDDEN)
        logInSpan.classList.toggle("expanded")
    })

    let loggedInUsername = document.createElement("span")
    logInSpan.appendChild(loggedInUsername)

    let accountManagerScreenOverlay = document.createElement("div")
    accountManagerScreenOverlay.classList.add("accountManagerScreenOverlay")
    logInSpan.appendChild(accountManagerScreenOverlay)

    let logInIcon = document.createElement("img")
    logInIcon.src = "assets/profile.svg"
    logInIcon.classList.add("logInIcon")

    logInSpan.appendChild(logInIcon)

    //Now, create the div that will be displayed when the user clicks the button.
    let accountDiv = document.createElement("div")
    accountDiv.classList.add("accountDiv")
    accountDiv.addEventListener("click", function(e) {
        e.stopPropagation() //Stop closing of logInSpan.
    })
    logInSpan.appendChild(accountDiv)

    let userNotLoggedInDiv = document.createElement("div")
    accountDiv.appendChild(userNotLoggedInDiv)

    let userLoggedInDiv = document.createElement("div")
    accountDiv.appendChild(userLoggedInDiv)

    let providers = ["Google", "Apple", "Email"]
    providers.forEach((provider) => {
        let providerButton = document.createElement("button")
        providerButton.classList.add("loginProviderButton")
        providerButton.classList.add("loginProviderButton" + provider)

        let providerLogo = document.createElement("img")
        providerLogo.classList.add("loginProviderLogo")
        providerLogo.src = `assets/loginProviders/${provider.toLowerCase()}.svg`

        providerButton.appendChild(providerLogo)
        providerButton.addEventListener("click", function() {
            signInWithProvider(provider)
        })

        let providerSignInText = document.createElement("span")
        providerSignInText.classList.add("loginProviderSignInText")
        providerSignInText.innerText = `Sign In With ${provider}`
        providerButton.appendChild(providerSignInText)

        userNotLoggedInDiv.appendChild(providerButton)
    })


    let emailPasswordForm = createEmailPasswordLoginForm()
    emailPasswordForm.style.display = "none"
    userNotLoggedInDiv.appendChild(emailPasswordForm)

    function signInWithProvider(provider) {
        console.log(provider)
        if (provider === "Google") {
            FirebaseAuthentication.signInWithGoogle()
        }
        else if (provider === "Apple") {
            FirebaseAuthentication.signInWithApple()
        }
        else if (provider === "Email") {
            emailPasswordForm.style.display = ""
        }
    }

    let userDetails = document.createElement("div")
    userLoggedInDiv.appendChild(userDetails)

    let changePasswordButtonDiv = createChangePasswordButton()
    userLoggedInDiv.appendChild(changePasswordButtonDiv)

    let signOutButton = document.createElement("button")
    signOutButton.innerText = "Sign Out"
    signOutButton.style.float = "right"
    userLoggedInDiv.appendChild(signOutButton)
    signOutButton.addEventListener("click", function() {
        FirebaseAuthentication.signOut()
    })

    let deleteAccountButton = document.createElement("button")
    deleteAccountButton.innerText = "Delete Account"
    userLoggedInDiv.appendChild(deleteAccountButton)
    deleteAccountButton.addEventListener("click", function() {
        if (confirm(`Are you sure you want to delete this account? This cannot be undone. `)) {
            FirebaseAuthentication.deleteUser()
        }
    })

    let closePopupMessage = document.createElement("div")
    closePopupMessage.style.marginTop = "0.5em"
    accountDiv.appendChild(closePopupMessage)

    let closePopupText = document.createElement("span")
    closePopupText.innerText = "To close this menu, click the shaded area, or click "
    closePopupMessage.appendChild(closePopupText)

    let closePopupButton = document.createElement("span")
    closePopupButton.innerText = "here"
    closePopupButton.style.fontWeight = "bold"
    closePopupButton.style.textDecoration = "underline"
    closePopupButton.addEventListener("click", function() {
        logInSpan.classList.remove("expanded")
    })
    closePopupMessage.appendChild(closePopupButton)

    let hadUserBefore;
    async function authStateChanged() {
        let isSignedIn;

        let authDetails = await FirebaseAuthentication.getCurrentUser()
        logInSpan.style.display = ""
        console.log(authDetails)
        if (!authDetails.user) {
            isSignedIn = false
            userNotLoggedInDiv.style.display = ""
            userLoggedInDiv.style.display = "none"
            loggedInUsername.innerText = "Log In"
        }
        else {
            isSignedIn = true
            userDetails.innerText = `Logged in as ${authDetails.user.email}`

            userNotLoggedInDiv.style.display = "none"
            userLoggedInDiv.style.display = ""

            let displayUsername = authDetails.user.displayName

            //Create display name from email if there's no displayName.
            if (!displayUsername) {
                displayUsername = authDetails.user.email
                displayUsername = displayUsername.slice(0, displayUsername.indexOf("@"))
            }

            //Clamp display name length
            let maxLength = 8
            if (displayUsername.length > maxLength) {
                displayUsername = displayUsername.slice(0, maxLength)
            }
            loggedInUsername.innerText = displayUsername
        }

        if (hadUserBefore !== isSignedIn) {
            //User successfully signed in or signed out - close popup
            logInSpan.classList.remove("expanded")
        }
        hadUserBefore = isSignedIn
    }
    FirebaseAuthentication.addListener('authStateChange', authStateChanged)
    authStateChanged()

    return logInSpan
}




export {createAccountManagerButton}



