const { FirebaseAuthentication } = require("@capacitor-firebase/authentication")
let weakPasswordErrorText = "This password is too short or too common. Please choose a stronger password and try again."

function createPasswordInputBox() {
    let passwordEntryContainer = document.createElement("div")
    
    let passwordEntry = document.createElement("input")
    passwordEntry.classList.add("loginEntry", "passwordEntry")
    passwordEntryContainer.appendChild(passwordEntry)

    let hidden = true
    let hideShowButton = document.createElement("img")
    hideShowButton.classList.add("passwordVisibilityToggle")
    function updateState() {
        passwordEntry.type = hidden ? "password" : "text"
        hideShowButton.src = `assets/loginProviders/${hidden ? "show" : "hide"}.svg`
    }
    hideShowButton.addEventListener("click", function() {
        hidden = !hidden
        updateState()
    })
    updateState()
    passwordEntryContainer.appendChild(hideShowButton)

    return {container: passwordEntryContainer, passwordEntry}
}


function createEmailPasswordLoginForm() {
    let emailPasswordForm = document.createElement("form")
    emailPasswordForm.addEventListener("click", (e) => {
        e.preventDefault() //Prevent form submission. 
    })

    function resetEmailPasswordForm() {
        while (emailPasswordForm.firstChild) {emailPasswordForm.firstChild.remove()}
    
        let emailEntry = document.createElement("input")
        emailEntry.type = "email"
        emailEntry.placeholder = "Enter Email..."
        emailEntry.autocomplete = "email"
        emailEntry.classList.add("loginEntry", "emailEntry")
        emailPasswordForm.appendChild(emailEntry)
    
    
        let passwordInput = createPasswordInputBox()
        passwordInput.passwordEntry.placeholder = "Enter Password..."
        passwordInput.passwordEntry.autocomplete = "current-password"
        emailPasswordForm.appendChild(passwordInput.container)
    
        let passwordConfirm = createPasswordInputBox()
        passwordConfirm.passwordEntry.placeholder = "Confirm Password..."
        passwordConfirm.passwordEntry.autocomplete = "new-password"
        passwordConfirm.container.style.display = "none"
        emailPasswordForm.appendChild(passwordConfirm.container)
    
        let signInButton = document.createElement("button")
        signInButton.innerText = "Sign In"
        emailPasswordForm.appendChild(signInButton)
    
        let createAccountButton = document.createElement("button")
        createAccountButton.innerText = "Create Account"
        createAccountButton.style.display = "none"
        emailPasswordForm.appendChild(createAccountButton)
    
        let resetFormButton = document.createElement("button")
        resetFormButton.innerText = "Reset Form"
        resetFormButton.style.float = "right"
        resetFormButton.addEventListener("click", function() {
            resetEmailPasswordForm()
        })
        emailPasswordForm.appendChild(resetFormButton)

        let switchToAccountCreator = document.createElement("button")
        switchToAccountCreator.innerText = "Create Account"
        switchToAccountCreator.addEventListener("click", openCreateAccountMenu)
        emailPasswordForm.appendChild(switchToAccountCreator)

        function openCreateAccountMenu() {
            //Open create account menu. 
            passwordConfirm.container.style.display = ""
            resetFormButton.style.display = ""
            sendPasswordResetEmail.style.display = "none"
            createAccountButton.style.display = ""
            signInButton.style.display = "none"
            switchToAccountCreator.style.display = "none"
}


        let sendPasswordResetEmail = document.createElement("button")
        sendPasswordResetEmail.innerText = "Reset Password"
        sendPasswordResetEmail.addEventListener("click", function() {
            if (confirm(`Are you sure you want to send a password reset email to ${emailEntry.value}?`)) {
                FirebaseAuthentication.sendPasswordResetEmail({"email": emailEntry.value})
            }
        })
        emailPasswordForm.appendChild(sendPasswordResetEmail)
    
    
    
        signInButton.addEventListener("click", function() {
            console.log("Clicked")
            FirebaseAuthentication.signInWithEmailAndPassword({
                email: emailEntry.value,
                password: passwordInput.passwordEntry.value
            }).catch((e) => {
                if (e.code === "auth/user-not-found") {
                    openCreateAccountMenu()
                }
                else if (e.code === "auth/wrong-password") {
                    alert("Incorrect password. ")
                }
                else {
                    alert("Error: " + (e.code || e.message))
                }
            })
        })
    
        createAccountButton.addEventListener("click", function() {
            if (passwordInput.passwordEntry.value !== passwordConfirm.passwordEntry.value) {
                alert("Password box does not match confirm password box")
                return
            }
            FirebaseAuthentication.createUserWithEmailAndPassword({
                email: emailEntry.value,
                password: passwordInput.passwordEntry.value
            }).then(() => {
                // FirebaseAuthentication.sendEmailVerification() //No need right now. 
            }, (e) => {
                if (e.code === "auth/weak-password") {
                    alert(weakPasswordErrorText)
                }
                else {
                    alert("Error: " + (e.code || e.message))
                }
            })
        })
    }
    
    
    resetEmailPasswordForm()
    return emailPasswordForm
}







function createChangePasswordButton() {
    let changePasswordDiv = document.createElement("form")
    changePasswordDiv.addEventListener("click", function(e) {
        e.preventDefault() //Prevent form submission. 
    })
    changePasswordDiv.style.display = "inline-block"

    //Chrome wants hidden username fields to help password managers (https://goo.gl/9p2vKq)
    //This is unnecessary but removes a warning from the browser in the console.
    let hiddenUsernameInput = document.createElement("input")
    hiddenUsernameInput.type = "text"
    hiddenUsernameInput.style.display = "none"
    hiddenUsernameInput.autocomplete = "username"
    FirebaseAuthentication.getCurrentUser().then((userObj) => {
        if (userObj?.user?.email) {
            hiddenUsernameInput.value = userObj.user.email
        }
    })
    changePasswordDiv.appendChild(hiddenUsernameInput)


    let passwordInput = createPasswordInputBox()
    passwordInput.passwordEntry.placeholder = "Enter New Password..."
    passwordInput.passwordEntry.autocomplete = "new-password"
    passwordInput.container.style.display = "none"
    changePasswordDiv.appendChild(passwordInput.container)

    let passwordConfirm = createPasswordInputBox()
    passwordConfirm.passwordEntry.placeholder = "Confirm New Password..."
    passwordConfirm.passwordEntry.autocomplete = "new-password"
    passwordConfirm.container.style.display = "none"
    changePasswordDiv.appendChild(passwordConfirm.container)

    let changePasswordButton = document.createElement("button")
    changePasswordButton.innerText = "Change Password"
    changePasswordDiv.appendChild(changePasswordButton)

    changePasswordButton.addEventListener("click", function() {
        if (passwordInput.container.style.display === "none") {
            //The first time the button is clicked we show the buttons. 
            passwordInput.container.style.display = ""
            passwordConfirm.container.style.display = ""
            changePasswordDiv.style.display = ""
        }
        else {
            //Successive times we attempt to update the password. 
            if (passwordInput.passwordEntry.value.length === 0) {
                alert("Please enter a new password")
                return
            }

            if (passwordInput.passwordEntry.value !== passwordConfirm.passwordEntry.value) {
                alert("Password box does not match confirm password box")
                return
            }

            FirebaseAuthentication.updatePassword({newPassword: passwordInput.passwordEntry.value}).then(() => {
                passwordInput.container.style.display = "none"
                passwordConfirm.container.style.display = "none"
                changePasswordDiv.style.display = "inline-block"

                passwordInput.passwordEntry.value = ""
                passwordConfirm.passwordEntry.value = ""
                alert("Password Change Successful")
            }, (e) => {
                if (e.code === "auth/requires-recent-login") {
                    alert("Apologies, however you have been logged in for quite some time. For security purposes, you must log out then log in again before your password can be updated. ")
                }
                else if (e.code === "auth/weak-password") {
                    alert(weakPasswordErrorText)
                }
                else {
                    alert("Error: " + (e.code || e.message))
                }
            })

        }
    })

    return changePasswordDiv
}


module.exports = {createEmailPasswordLoginForm, createChangePasswordButton}